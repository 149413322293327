import React from "react";
import "./Home.css";
import { useEffect } from "react";
import Header from "../../components/Headers/Header";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
// Assuming you have a separate CSS file for styling

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration (optional)
      once: true, // Whether animation should happen only once
    });
  }, []);

  let eventList = [
    {
      name: "Yalemun",
      image: "assets/Frame 110.png",
    },
    {
      name: "Harvard MUN",
      image: "assets/Frame 111.png",
    },
    {
      name: "Cambridge MUN",
      image: "assets/Frame 112.png",
    },
    {
      name: "UN HQ",
      image: "assets/Frame 113.png",
    },
  ];

  let allEvents = [
    {
      name: "UN Headquarters",
      image: "assets/event_7.jpg",
      date: "3rd – 6th April, 2025",
      status: 0,
      location: "New York",
    },
    {
      name: "UN Headquarters",
      image: "assets/event_7.jpg",
      date: "24th – 27th July, 2025",
      status: 0,
      location: "New York",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <Header />
      <div className="section">
        <img
          className="img_background"
          src={"assets/Group 43.png"}
          alt="TEAMMJ"
        />
        <img
          className="students_img"
          src={"assets/Frame 98.png"}
          alt="students_pic"
        />
        <img className="tag" src={"/assets/Frame 99.png"} alt="tag" />
        <div className="contentbox">
          <div className="heroHeading">
            <p className="content" id="line1">
              generating tomorrow's trailblazers
            </p>
            <p className="content" id="line2">
              one victory at a time
            </p>
          </div>
          <div className="content" id="parabox">
            <p className="para">
              Discover a transformative journey towards empowerment and
              excellence, where young minds are equipped with the skills and
              confidence needed to conquer the world. Explore how we nurture
              tomorrow's leaders.
            </p>
          </div>
          <div className="content_footer">
            <a
              className="text"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/_teammj/?igsh=MWJtNDN0cXZwZGZreQ%3D%3D"
            >
              See TEAM MJ in action
              {/* <img id="link" src="assets/Play.png" alt="play" /> */}
            </a>
            <span className="text" id="or">
              or
            </span>
            <div className="register" id="button2">
              <a href="pages/register.html">Register Now</a>
            </div>
          </div>
        </div>
      </div>

      <img className="vector" src={"assets/Vector.png"} alt="vector" />
      <div className="approachsec">
        <div className="approachbox">
          <div data-aos="fade-right" className="left">
            <img src="assets/Group.png" alt="sparkle" />
          </div>
          <div className="approach">
            <p className="approachcontent" id="head">
              our approach
            </p>
            <p className="approachcontent" id="app1">
              Navigating the complexities of MUN can be challenging for young
              debaters and limited access to quality coaching and resources
              holds students back from reaching their full potential.
            </p>
            <p className="approachcontent" id="app2">
              We provide expert coaching and mentorship tailored to each
              student's needs and comprehensive resources and support to
              overcome MUN challenges.
            </p>
          </div>
          <div data-aos="fade-left" className="right">
            <img src="assets/Group.png" alt="sparkle" />
          </div>
        </div>
      </div>

      <div className="introsec">
        <div className="introbox">
          <div className="sound">
            <img
              className="announce"
              src={"assets/announce.png"}
              alt="announcement"
            />
          </div>
          <div className="intro">
            <p id="introhead">introducing</p>
            <p id="name">murtaza jafry</p>
            <p id="intropara">
              Dedicated mentor, visionary leader. Meet Murtaza Jafry, the
              driving force behind Team MJ. With a passion for nurturing young
              talent, Murtaza inspires excellence and empowers tomorrow's
              leaders to shine bright.
            </p>
            <div className="bio">
              <div className="icons">
                <img src="assets/Users Group Two Rounded.svg" alt="hello" />
                <p className="biotext">150+ Students</p>
              </div>
              <div className="icons">
                <img src="assets/Medal Ribbon Star.svg" alt="" />
                <p className="biotext">42 Wins</p>
              </div>
              <div className="icons">
                <img src="assets/Bolt.svg" alt="" />
                <p className="biotext">8+ Years of Experience</p>
              </div>
            </div>
          </div>

          <img className="profile" src={"assets/profile.png"} alt="" />
        </div>
      </div>

      <div className="locsec">
        <div className="reasoncontainer">
          <div className="reason">
            <p id="ques">WHY YOU SHOULD JOIN TEAM MJ</p>
            <p id="ans">
              Apart from developing life skills, Team MJ takes the opportunity
              to expand horizons by participating in prestigious MUNs built for
              greatness. The most notable ones are Yale University, Harvard
              University, and Cambridge University.
            </p>
          </div>
        </div>
        <div className="locimg">
          {/* Add your images here */}
          {eventList.map((event, i) => (
            <div
              className="locimgitem"
              data-aos="fade-up"
              style={{ position: "relative" }}
            >
              <img
                loading="lazy"
                className="locs"
                src={event.image}
                alt={event.name}
              />
              <div className="bottomleft">
                <p className="munHeading">{event.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="eventsec">
        <p id="eventhead">Events</p>
        <div data-aos="zoom-in-down" className="eventbox">
          {allEvents.map((event, i) => (
            <div
              className="events"
              style={{
                height: "204px",
                display: "flex",
              }}
            >
              <img
                loading="lazy"
                id="eventimg"
                src={event.image}
                alt="students"
              />
              <div class="eventcontent">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div class="lochead">
                      <p class="location">{event.name}</p>
                      <div class="highlight">
                        <p>Accepting Applications</p>
                      </div>
                    </div>
                    <div className="text-start">
                      <p class="Pz_Mz" id="date">
                        {event.date} <em>USA</em>
                      </p>
                      {event.deadline && (
                        <p class="Pz_Mz" id="date">
                          Deadline: {event.deadline}
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    id="eventbutton"
                    style={{
                      height: "52px",
                      width: "100%",
                    }}
                  >
                    <a href="/register">
                      <p>Register Now</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div class="opportunity">
        <img
          loading="lazy"
          src="assets/Sketch-annotation-element-brush-pen-illustrations-achievement-cup.png"
          alt="Illustration of a trophy representing achievement"
        />
        <div class="motivation">
          <h2 id="motivationhead">Unlock Your Potential</h2>
          <p id="motivationtext">
            Don't miss your chance to embark on a journey of excellence with
            Team MJ. Join our community of aspiring leaders and equip yourself
            with the skills needed to conquer the world of Model United Nations.
          </p>

          <div class="registerbox">
            <div class="register">
              <a href="pages/register.html" class="register-link">
                Register Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <section class="blogsec">
        <h2 class="blog">Blogs</h2>
        <div class="navbar">
          {/* <button
            class="blogbutton"
            id="winsTab"
            onclick="changeTabHomePage(event,'win')"
          >
            <p>MUN Wins</p>
          </button> */}
        </div>
        <div id="recent" className="blogdisplayyy">
          <div data-aos="fade-right" className="blogs">
            <img
              className="blogimg"
              src="../assets/aboutus_6.png"
              alt="Blog 1"
            />
            <div class="blogcontent">
              <p class="bloghead1">
                Pioneering Change- Murtaza Jafry’s MUN Initiative in Pakistan
                Redefines Education Across the Nation
              </p>
              <p class="blogpara1">
                A decade ago, Murtaza Jafry embarked on a personal research
                journey to understand the shortcomings of Pakistan’s education
                system. He sought to uncover why Pakistani students were not
                excelling at international levels, despite their potential.
                 Through his research, he identified some major areas that are
                vastly unexplored but greatly essential as life skills: critical
                thinking, research, and communication skills. This discovery
                ignited a drive within him to revolutionise the education
                landscape in Pakistan. The journey of understanding how to
                break...
              </p>
              <a
                target="_blank"
                href="https://startuppakistan.com.pk/pioneering-change-murtaza-jafrys-mun-initiative-in-pakistan-redefines-education-across-the-nation/"
              >
                <button id="about_readmore">Read More</button>
              </a>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1500" className="blogs">
            <img
              className="blogimg"
              src="../assets/aboutus_7.png"
              alt="Blog Image 2"
            />
            <div class="blogcontent">
              <p class="bloghead1">
                Pakistan's Team Mj Wins Laurels at Cambridge Model United
                Nations 2024
              </p>
              <p class="blogpara1">
                What started as a casual introduction unfolded into a
                transformative experience with Team MJ, which Shahvaiz describes
                as less of a MUN class and more of a close-knit family driven by
                a shared passion for debating and winning. The unique atmosphere
                within Team MJ, characterized by camaraderie and a collective
                passion for debate, fostered an environment where Shahvaiz not
                only honed his debating skills but also grew as an individual.
              </p>

              <a
                target="_blank"
                href="https://wow360.pk/pakistans-team-mj-wins-laurels-at-cambridge-model-united-nations-2024/"
              >
                <button id="about_readmore">Read More</button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div class="vidcontainer">
        <div data-aos="zoom-in" data-aos-duration="1500">
          <iframe
            className="rounded-md border-none my-4 w-[50%] h-[380px]"
            src="https://drive.google.com/file/d/149fwygVvDj1z09-4P2vsbqcSCAOJsVjP/preview"
            allow="autoplay"
            frameBorder="0"
            allowFullScreen
            title="Embedded Video"
          ></iframe>
          {/* <video controls width="640" height="480">
            <source
              src="https://drive.google.com/uc?export=preview&id=149fwygVvDj1z09-4P2vsbqcSCAOJsVjP"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video> */}
          <p>
            Here’s a glimpse of Team MJ’s recent success on making an
            international mark by securing admirable 10 laurels at the United
            Nations Headquarters, New York, USA.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
